import { Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { MenuItem } from 'components';

export interface MenuLogoutProps {
  onClick: () => void;
  label: string;
}

export const MenuItemLogout = ({ onClick, label }: MenuLogoutProps) => {
  return (
    <MenuItem
      muiItemProps={{
        sx: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'error.main',
        },
      }}
      action={onClick}
      data-cy="components_Navbar_logout-link"
    >
      <Typography>{label}</Typography>
      <ExitToAppIcon />
    </MenuItem>
  );
};
