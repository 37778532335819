/* eslint-disable camelcase */
// external
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';

// assets
import { useAuth0 } from '@auth0/auth0-react';
import { browserHistory } from 'utils/browserHistory';

// store
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { hideNavbar } from 'store/slices/appSlice';

export function NotFound() {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const { isAuthenticated } = useAuth0();
  const { generic_errors } = baseLanguage;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { settings } = useSanitySettings({});

  const handleRedirect = () => {
    isAuthenticated
      ? browserHistory.push('/programs')
      : browserHistory.push('/');
  };

  useEffect(() => {
    hideNavbar(true);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 100px)',
        minHeight: !isMobile ? 700 : 500,
        textAlign: 'center',
        flexDirection: 'column',
        maxWidth: '844px',
        margin: '0 auto',
        ...(isMobile && { paddingX: '15px' }),
      }}
    >
      <Box sx={{ width: isMobile ? '100%' : '620px' }}>
        <img
          src={buildSanityImageUrl({
            source: settings?.static_media.common.error_404 ?? '',
          })}
          alt={generic_errors.not_found_404_error.not_found_404_error_title}
          style={{ width: '100%' }}
        />
      </Box>
      <Typography variant="h4" component="h1" sx={{ marginTop: '32px' }}>
        {generic_errors.not_found_404_error.not_found_404_error_title}
      </Typography>
      <Typography
        variant={isMobile ? 'h6' : 'body1'}
        component="p"
        sx={{
          color: theme.palette.text.secondary,
          marginTop: '10px',
          marginBottom: '24px',
        }}
      >
        {generic_errors.not_found_404_error.not_found_404_error_body}
      </Typography>
      <Button
        data-cy="components_NotFound_404-error-button"
        onClick={handleRedirect}
        variant="contained"
        fullWidth={isMobile}
        size="large"
        color="info"
      >
        {generic_errors.not_found_404_error.not_found_404_button_label}
      </Button>
    </Box>
  );
}
