// external
import React from 'react';
import { Backdrop, Box } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';

// assets
import guiderWordMark from 'assets/guider-word-mark.svg';

// store
import { RootState } from 'store';
import { useAuth0 } from '@auth0/auth0-react';

const mapState = (state: RootState) => {
  return {
    isAppLoading: state.app.loading,
  };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const LoadingElement = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Box
        component="img"
        sx={{ width: '200px', animation: 'pulse 2s infinite' }}
        src={guiderWordMark}
        alt=""
      />
    </Box>
  );
};

export const LoadingComponent: React.FunctionComponent<PropsFromRedux> = ({
  isAppLoading,
}) => {
  const { isLoading } = useAuth0();

  return (
    <Backdrop
      open={isAppLoading || isLoading}
      transitionDuration={1000}
      appear={false}
      sx={{ zIndex: 10 }}
    >
      <LoadingElement />
    </Backdrop>
  );
};

export const Loading = connector(LoadingComponent);
