import { SanityAdditionalNavigationLink } from '@guider-global/shared-types';
import { ListItemButton, Collapse, Link } from '@mui/material';
import { useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

export interface NestedListAdditionalNavLinksProps {
  label: string;
  links: SanityAdditionalNavigationLink[];
  linkActionCallback?: () => void;
}
export const NestedListAdditionalNavLinks = ({
  label,
  links,
  linkActionCallback,
}: NestedListAdditionalNavLinksProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((c) => !c);
  };

  const handleAction = () => {
    if (linkActionCallback) {
      linkActionCallback();
    }
    setOpen(false);
  };

  if (links.length === 0) return <></>;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {label}
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <>
          {links.map((link, index) => {
            return (
              <ListItemButton
                key={`additional-nav-link-${link._key}`}
                component={Link}
                href={link.url}
                target={link.target}
                color="inherit"
                onClick={handleAction}
                sx={{
                  ml: 2,
                  fontSize: '1rem',
                  padding: 2,
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                {link.label}
              </ListItemButton>
            );
          })}
        </>
      </Collapse>
    </>
  );
};
