/* eslint-disable camelcase */

import { FC } from 'react';
import { SanityContentLibrary } from '@guider-global/shared-types';
import { Box, Grid, Typography } from '@mui/material';

import {
  buildSanityFileUrl,
  buildSanityImageUrl,
} from '@guider-global/sanity-hooks';
import { Header } from '../Header';

type Props = SanityContentLibrary;

export const Audio: FC<Props> = ({
  content: { excerpt, image, title, audio_file },
  metadata: { type },
}) => {
  const fileUrl = audio_file
    ? buildSanityFileUrl({ source: audio_file.asset })
    : '';

  return (
    <>
      <Grid item xs={12} sm={8} md={6} sx={{ mx: 'auto' }}>
        <Box
          component="img"
          src={buildSanityImageUrl({ source: image })}
          sx={{
            height: '280px',
            width: '100%',
            borderRadius: 1,
            objectFit: 'cover',
            mb: 3,
          }}
        />
        <Header {...{ heading: title, type }} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {excerpt ? excerpt : ''}
        </Typography>
        <Box
          component="audio"
          controls
          src={fileUrl}
          sx={{
            borderRadius: 1,
            border: 'none',
            width: '100%',
            background: 'white',
          }}
        />
      </Grid>
    </>
  );
};
