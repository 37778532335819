// external
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useMobileMediaQuery } from 'hooks';

interface FlatPageHeaderProps {
  header: string;
  subheader: string;
}

export const FlatPageHeader: React.FC<FlatPageHeaderProps> = ({
  header,
  subheader,
}) => {
  const isMobile = useMobileMediaQuery();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        pt: isMobile ? 3 : 8,
        pb: isMobile ? 2 : 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          width: isMobile ? '95%' : '70%',
          maxWidth: '1440px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'flex-start' : 'flex-end',
          mx: 'auto',
          px: 1.5,
        }}
      >
        <Box>
          <Typography component="h1" variant="h4">
            {header}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {subheader}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
