import { Box, PopoverVirtualElement } from '@mui/material';
import { INavbarLink } from '..';
import { NavButton } from './NavButton';
import { Menu, MenuItemProps } from 'components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export interface NavDesktopLinksProps {
  links: INavbarLink[];
  additionalNavLinks?: MenuItemProps[];
  additionalNavLinksLabel: string;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  onDesktopLinksClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDesktopLinksClickClosed: (
    event?: unknown,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  isAuthenticated?: boolean;
}

export const NavDesktopLinks = ({
  links,
  additionalNavLinks,
  additionalNavLinksLabel,
  anchorEl,
  onDesktopLinksClickOpen,
  onDesktopLinksClickClosed,
  isAuthenticated,
}: NavDesktopLinksProps) => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
        }}
      >
        {isAuthenticated &&
          links.map((link, index) => (
            <NavButton
              link={link}
              key={link.key ?? `desktop-link-${index}`}
              data-cy={`components_Navbar_authenticated_${link.label}-button`}
            />
          ))}
        <Menu
          items={additionalNavLinks ?? []}
          anchorEl={anchorEl}
          onMenuClickOpen={onDesktopLinksClickOpen}
          onMenuClickClosed={onDesktopLinksClickClosed}
          hidden={additionalNavLinks?.length === 0}
        >
          {additionalNavLinksLabel}
          {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Menu>
      </Box>
    </>
  );
};
