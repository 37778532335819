import { IOrganizationLanguage } from '@guider-global/sanity-hooks';
import { Box } from '@mui/material';
import { LanguageMenu } from 'components/LanguageMenu';
import { INavbarLink } from '..';
import { NavButton } from '../NavDesktopLinks/NavButton';

export interface NavUnauthenticatedActionsProps {
  show?: boolean;
  actions: INavbarLink[];
  localeCode?: string;
  languageOptions: IOrganizationLanguage[];
  ariaLabelLanguage: string;
  onClickChangeLanguage: (localeCode: string) => void;
}

export const NavUnauthenticatedActions = ({
  show,
  actions,
  languageOptions,
  localeCode,
  ariaLabelLanguage,
  onClickChangeLanguage,
}: NavUnauthenticatedActionsProps) => {
  if (!show) return <></>;
  return (
    <>
      <LanguageMenu
        localeCode={localeCode}
        languageOptions={languageOptions}
        ariaLabel={ariaLabelLanguage}
        onClickChange={onClickChangeLanguage}
      />
      <Box component="nav" sx={{ display: { xs: 'none', md: 'flex' } }}>
        {actions.map((link, index) => {
          return (
            <NavButton
              link={link}
              key={link.key ?? `link-${index}`}
              data-cy={`components_Navbar_unauthenticated_${link.label}-button`}
              isUnauthenticated
            />
          );
        })}
      </Box>
    </>
  );
};
