/* eslint-disable camelcase */

import { FC } from 'react';

import { SanityContentLibrary } from '@guider-global/shared-types';
import { Box, Grid, Typography } from '@mui/material';

import {
  buildSanityFileUrl,
  buildSanityImageUrl,
} from '@guider-global/sanity-hooks';
import { Header } from '../Header';

type Props = SanityContentLibrary;

export const PDF: FC<Props> = ({
  content: { excerpt, image, title, embedded_pdf },
  metadata: { type },
}) => {
  const fileUrl = embedded_pdf
    ? buildSanityFileUrl({ source: embedded_pdf.asset })
    : '';

  return (
    <>
      <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
        <Box
          component="img"
          src={buildSanityImageUrl({ source: image })}
          sx={{
            height: '280px',
            width: '100%',
            borderRadius: 1,
            objectFit: 'cover',
            mb: 3,
          }}
        />
        <Header {...{ heading: title, type }} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {excerpt ? excerpt : ''}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} sx={{ mx: 'auto' }}>
        <Box
          component="iframe"
          src={fileUrl}
          width="100%"
          height="70vh"
          sx={{
            borderRadius: 1,
            border: 'none',
          }}
        />
      </Grid>
    </>
  );
};
