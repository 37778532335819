import { Typography, Box } from '@mui/material';
import { AvatarUser } from 'components/AvatarUser';
import { Link as RouterLink } from 'react-router-dom';
import { IProfile, IUser } from '@guider-global/shared-types';
import { MenuItem } from 'components';

export interface MenuProfileCardProps {
  onClick: () => void;
  label: string;
  to: string;
  profile?: IProfile;
  user?: IUser;
}

export const MenuProfileCard = ({
  onClick,
  to,
  user,
  profile,
}: MenuProfileCardProps) => {
  return (
    <MenuItem
      component={RouterLink}
      to={to}
      action={onClick}
      data-cy="components_Navbar_profile-button"
      muiItemProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pr: 12,
        },
      }}
    >
      <AvatarUser
        sx={{
          width: 50,
          height: 50,
          mr: 1,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
        }}
      >
        <Typography variant="body2" component={'span'}>
          {profile?.displayName}
        </Typography>
        <Typography
          component={'span'}
          sx={{
            fontSize: '12px',
            color: 'text.secondary',
          }}
        >
          {user?.email}
        </Typography>
      </Box>
    </MenuItem>
  );
};
