import { Menu } from '@mui/material';
import { useState } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { IOrganizationLanguage } from '@guider-global/sanity-hooks';
import { LanguageButton } from 'components/LanguageButton';

export interface LanguageMenuProps {
  localeCode?: string;
  languageOptions: IOrganizationLanguage[];
  ariaLabel: string;
  onClickChange: (localeCode: string) => void;
  arrowColor?: string;
}

export const LanguageMenu = ({
  languageOptions,
  localeCode,
  ariaLabel,
  onClickChange,
  arrowColor = 'primary.contrastText',
}: LanguageMenuProps) => {
  const [languagesMenuAnchor, setLanguagesMenuAnchor] =
    useState<null | HTMLElement>(null);
  const handleLanguagesMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setLanguagesMenuAnchor(event.currentTarget);
  };
  const handleLanguagesMenuClose = () => {
    setLanguagesMenuAnchor(null);
  };
  const handleClickChange = (localeCode: string) => {
    onClickChange(localeCode);
    handleLanguagesMenuClose();
  };

  const languageOption = languageOptions?.find(
    (languageOption) => languageOption.locale_code === localeCode,
  )?.flag;

  if (!languageOptions || languageOptions.length <= 1) return null;

  return (
    <>
      <LanguageButton
        id="language-option-button"
        onClick={handleLanguagesMenuClick}
        aria-label={ariaLabel}
        aria-controls="language-options-menu"
        aria-haspopup="true"
        aria-expanded={languagesMenuAnchor ? 'true' : 'false'}
        flag={languageOption}
        endIcon={
          languagesMenuAnchor ? (
            <ArrowDropUp sx={{ color: arrowColor }} />
          ) : (
            <ArrowDropDown sx={{ color: arrowColor }} />
          )
        }
      />

      <Menu
        id="language-options-menu"
        anchorEl={languagesMenuAnchor}
        open={Boolean(languagesMenuAnchor)}
        onClose={handleLanguagesMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { width: '320px' } }}
      >
        {languageOptions?.map(({ flag, display_name, locale_code }) => (
          <LanguageButton
            key={flag?.unified}
            flag={flag}
            onClick={() => handleClickChange(locale_code)}
            selected={locale_code === localeCode}
            disabled={locale_code === localeCode}
            fullWidth
          >
            {display_name}
          </LanguageButton>
        ))}
      </Menu>
    </>
  );
};
