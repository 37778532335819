import { IconButton, Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { SanityOrganizationWhiteLabelLogoSize } from '@guider-global/shared-types';

// assets
import logoGuider from 'assets/logo-guider.svg';

export interface NavLogoProps {
  src?: string;
  size?: SanityOrganizationWhiteLabelLogoSize;
}

const LOGO_SIZE_MULTIPLIER = {
  [SanityOrganizationWhiteLabelLogoSize.Default]: 1,
  [SanityOrganizationWhiteLabelLogoSize.Large]: 2,
  [SanityOrganizationWhiteLabelLogoSize.ExtraLarge]: 3,
};

export const NavLogo = ({
  src = logoGuider,
  size = SanityOrganizationWhiteLabelLogoSize.Default,
}: NavLogoProps) => {
  const multiplier = LOGO_SIZE_MULTIPLIER[size];

  return (
    <Box sx={{ marginRight: '12px' }}>
      <Link component={RouterLink} to="/">
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{
            mr: 1,
            color: '#ffffff',
            my: '5px',
            mx: { xs: 0, md: '-12px' },
          }}
        >
          <img
            alt="Logo"
            src={src}
            style={{
              minHeight: '24px',
              maxHeight: `${multiplier * 24}px`,
              maxWidth: '100%',
            }}
          />
        </IconButton>
      </Link>
    </Box>
  );
};
