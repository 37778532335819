//external
import { Box } from '@mui/material';
import { forwardRef, useCallback } from 'react';
// components
import {
  LandingPageBenefits,
  LandingPageCallToAction,
  LandingPageSingleQuote,
  LandingPageTestimonials,
  LandingPageTextWithImage,
  LandingPageTextColumns,
  LandingPageLogos,
} from 'components/LandingPage';
import LandingPageFaqs from '../LandingPageFaqs';
import LandingPageHowItWorks from '../LandingPageHowItWorks';

// store
import { useAppSelector } from 'store/hooks';
import { selectRegistrationType } from 'store/slices/appSlice';

// Types
import { SanityLandingPageContent } from '@guider-global/shared-types';

export interface ILoginRegisterButtonProps {
  onClickRegisterButton: () => void;
  onClickProgramButtons?: (isGuide?: boolean, pathname?: string) => void;
  showLoginButtons?: boolean;
}
interface ILandingPageContentProps
  extends Omit<ILoginRegisterButtonProps, 'showLoginButtons'> {
  config: SanityLandingPageContent;
}

export const LandingPageContent = forwardRef<unknown, ILandingPageContentProps>(
  ({ config, onClickProgramButtons, onClickRegisterButton }, ref) => {
    // Redux
    const registrationPageType = useAppSelector(selectRegistrationType);
    const isShowLoginButtons = registrationPageType !== 'none';

    const renderContent = useCallback(
      () =>
        config.map((section, index) => {
          switch (section._type) {
            case 'landingPageSingleQuote': {
              return (
                <LandingPageSingleQuote
                  key={`${section._type}-${index}`}
                  config={section}
                />
              );
            }
            case 'landingPageTextWithImage': {
              return (
                <LandingPageTextWithImage
                  key={`${section._type}-${index}`}
                  config={section}
                  onClickProgramButtons={onClickProgramButtons}
                  onClickRegisterButton={onClickRegisterButton}
                  showLoginButtons={isShowLoginButtons}
                />
              );
            }
            case 'landingPageLogos': {
              return (
                <LandingPageLogos
                  key={`${section._type}-${index}`}
                  config={section}
                />
              );
            }
            case 'landingPageBenefits': {
              return (
                <LandingPageBenefits
                  key={`${section._type}-${index}`}
                  config={section}
                  onClickProgramButtons={onClickProgramButtons}
                  onClickRegisterButton={onClickRegisterButton}
                  showLoginButtons={isShowLoginButtons}
                />
              );
            }
            case 'landingPageCallToAction': {
              return (
                <LandingPageCallToAction
                  key={`${section._type}-${index}`}
                  config={section}
                  onClickProgramButtons={onClickProgramButtons}
                  onClickRegisterButton={onClickRegisterButton}
                  showLoginButtons={isShowLoginButtons}
                />
              );
            }
            case 'landingPageTestimonials': {
              return (
                <LandingPageTestimonials
                  key={`${section._type}-${index}`}
                  config={section}
                />
              );
            }
            case 'landingPageTextColumns': {
              return (
                <LandingPageTextColumns
                  key={`${section._type}-${index}`}
                  config={section}
                />
              );
            }

            case 'landingPageFaqs': {
              return (
                <LandingPageFaqs
                  key={`${section._type}-${index}`}
                  config={section}
                />
              );
            }

            case 'landingPageHowItWorks': {
              return (
                <LandingPageHowItWorks
                  key={`${section._type}-${index}`}
                  config={section}
                  onClickProgramButtons={onClickProgramButtons}
                  onClickRegisterButton={onClickRegisterButton}
                  showLoginButtons={isShowLoginButtons}
                />
              );
            }

            default:
              return <></>;
          }
        }),
      [
        config,
        onClickProgramButtons,
        onClickRegisterButton,
        isShowLoginButtons,
      ],
    );

    return (
      <Box sx={{ scrollMarginTop: '60px', overflowX: 'hidden' }} ref={ref}>
        {renderContent()}
      </Box>
    );
  },
);
