/* eslint-disable camelcase */

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { SanityLearningHubCallout } from '@guider-global/shared-types';

import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { useMobileMediaQuery } from 'hooks';
import { getContrastColor } from '@guider-global/ui';

type Props = SanityLearningHubCallout;

const Callout: React.FC<Props> = ({
  callout_title,
  callout_description,
  callout_image,
  callout_transparent_image,
  change_background_colour,
  background_colour,
}) => {
  const isMobile = useMobileMediaQuery();

  const imageUrl = buildSanityImageUrl({
    source: callout_image,
    width: 1440,
  });

  const determineBackground = () => {
    if (change_background_colour) {
      return background_colour.hex;
    } else {
      return isMobile
        ? `linear-gradient(155.49deg, #2729A5 35.32%, #2E84F5 91.68%)`
        : `linear-gradient(93.94deg, #2729A5 22.78%, #2E84F5 101.33%)`;
    }
  };
  return (
    <Box
      sx={{
        background: determineBackground(),
        width: '100%',
        mb: 2,
      }}
    >
      <Grid
        container
        columns={12}
        sx={{
          width: isMobile ? '95%' : '83vw',
          maxWidth: '100%',
          alignItems: 'center',
          mx: 'auto',
          my: 0,
          py: 8,
        }}
      >
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            pl: 1,
            pr: isMobile ? 1 : 3,
            ...(isMobile && { textAlign: 'center', justifyContent: 'center' }),
          }}
        >
          <Box
            sx={{
              width: '118px',
              height: '4px',
              borderRadius: '10px',
              backgroundColor: 'white',
              my: isMobile ? 3 : 1,
              ...(isMobile && { mx: 'auto' }),
            }}
          />
          <Typography
            variant="h4"
            component="h2"
            color={getContrastColor(determineBackground())}
          >
            {callout_title}
          </Typography>
          <Typography
            variant="body1"
            color={getContrastColor(determineBackground())}
            sx={{
              my: isMobile ? 3 : 1,
              lineHeight: '150%',
              letterSpacing: '0.15px',
            }}
          >
            {callout_description}
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pl: 1,
            pr: isMobile ? 1 : 3,
          }}
        >
          <Box
            component={'img'}
            alt={callout_title}
            src={imageUrl}
            sx={{
              borderRadius: '8px',
              maxHeight: '340px',
              maxWidth: '100%',
              height: isMobile ? 'auto' : '100%',
              ...(!callout_transparent_image && {
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }),
            }}
          ></Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Callout;
