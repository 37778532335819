import {
  IconButton,
  Menu,
  Divider,
  PopoverVirtualElement,
} from '@mui/material';
import { AvatarUser } from 'components/AvatarUser';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { IProfile, IUser } from '@guider-global/shared-types';
import {
  MenuItems,
  MenuItemProps,
  MenuProfileCard,
  MenuItemLogout,
} from 'components';
import { LanguageNested } from 'components/LanguageNested';
import { IOrganizationLanguage } from '@guider-global/sanity-hooks';

export interface NavUserMenuProps {
  items: MenuItemProps[];
  profileLabel: string;
  profileTo: string;
  profile?: IProfile;
  user?: IUser;
  onClickLogout: () => void;
  logoutLabel: string;
  onMenuClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMenuClickClosed: (
    event?: unknown,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  show?: boolean;
  languageOptions: IOrganizationLanguage[];
  ariaLabelLanguage: string;
  onChangeLocaleCode: (localeCode: string) => void;
  localeCode?: string;
}
export const NavUserMenu = ({
  items,
  profileLabel,
  profileTo,
  user,
  profile,
  onClickLogout,
  logoutLabel,
  anchorEl,
  onMenuClickOpen,
  onMenuClickClosed,
  show,
  languageOptions,
  ariaLabelLanguage,
  onChangeLocaleCode,
  localeCode,
}: NavUserMenuProps) => {
  if (!show) return <></>;
  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        onClick={onMenuClickOpen}
        type="button"
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : 'false'}
        aria-controls="user-menu"
        sx={{ mr: { xs: -1, md: 0 } }}
        data-cy="components_Navbar_avatar-button"
      >
        <AvatarUser
          sx={{
            width: 40,
            height: 40,
          }}
        />
        <ArrowDropDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        aria-hidden={anchorEl ? 'true' : 'false'}
        open={Boolean(anchorEl)}
        onClose={onMenuClickClosed}
        id="user-menu"
      >
        <MenuProfileCard
          onClick={onMenuClickClosed}
          label={profileLabel}
          to={profileTo}
          user={user}
          profile={profile}
        />
        <Divider />
        <MenuItems items={items} />
        <Divider />
        <MenuItemLogout onClick={onClickLogout} label={logoutLabel} />
        <Divider />
        <LanguageNested
          localeCode={localeCode}
          languageOptions={languageOptions}
          ariaLabel={ariaLabelLanguage}
          onClickChange={onChangeLocaleCode}
        />
      </Menu>
    </>
  );
};
