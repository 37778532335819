// external
import React from 'react';
import { Backdrop, Box } from '@mui/material';

// assets
import guiderWordMark from 'assets/guider-word-mark.svg';

interface ILoadingOverlayProps {
  loading?: boolean;
  transitionDuration?:
    | number
    | {
        appear?: number;
        enter?: number;
        exit?: number;
      };
}

export const LoadingOverlay: React.FunctionComponent<ILoadingOverlayProps> = ({
  loading = false,
  transitionDuration = {
    appear: 1000,
    enter: 1000,
    exit: 1000,
  },
}) => {
  return (
    <Backdrop
      open={loading}
      sx={{ zIndex: 10 }}
      transitionDuration={transitionDuration}
      appear={false}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <Box
          component="img"
          sx={{ width: '200px', animation: 'pulse 2s infinite' }}
          src={guiderWordMark}
          alt=""
        />
      </Box>
    </Backdrop>
  );
};
