import { Collapse } from '@mui/material';
import { useState } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { IOrganizationLanguage } from '@guider-global/sanity-hooks';
import { Stack } from '@guider-global/ui';
import { LanguageButton } from 'components/LanguageButton';

export interface LanguageNestedProps {
  localeCode?: string;
  languageOptions: IOrganizationLanguage[];
  ariaLabel: string;
  onClickChange: (localeCode: string) => void;
}

export const LanguageNested = ({
  languageOptions,
  localeCode,
  ariaLabel,
  onClickChange,
}: LanguageNestedProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleLanguagesMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setOpen((c) => !c);
  };
  const handleLanguagesMenuClose = () => {
    setOpen(false);
  };
  const handleClickChange = (localeCode: string) => {
    onClickChange(localeCode);
    handleLanguagesMenuClose();
  };

  const languageOption = languageOptions?.find(
    (languageOption) => languageOption.locale_code === localeCode,
  )?.flag;

  if (!languageOptions || languageOptions.length === 1) return null;

  return (
    <Stack direction="column" align="left" spacing={0} sx={{ width: '100%' }}>
      <LanguageButton
        aria-label={ariaLabel}
        aria-controls="language-options-list"
        aria-expanded={open ? 'true' : 'false'}
        flag={languageOption}
        onClick={handleLanguagesMenuClick}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
      />
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ width: '100%' }}
        id="language-options-list"
      >
        {languageOptions?.map(({ flag, display_name, locale_code }) => (
          <LanguageButton
            key={flag?.unified}
            flag={flag}
            onClick={() => handleClickChange(locale_code)}
            selected={locale_code === localeCode}
            disabled={locale_code === localeCode}
            fullWidth
          >
            {display_name}
          </LanguageButton>
        ))}
      </Collapse>
    </Stack>
  );
};
