import { MenuItem, MenuItemProps } from 'components/MenuItem';

export interface MenuItemsProps {
  items: MenuItemProps[];
}
export const MenuItems = ({ items }: MenuItemsProps) => {
  return (
    <>
      {items.map((item, index) => (
        <MenuItem
          key={item?.key || `menu-item-${index}`}
          action={item?.action}
          href={item?.href}
          to={item?.to}
          component={item?.component}
          data-cy={item['data-cy']}
          label={item?.label}
          muiItemProps={item?.muiItemProps}
        >
          {item?.children}
        </MenuItem>
      ))}
    </>
  );
};
