import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import { AppBar, Link, Toolbar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useState } from 'react';

import { NavLogo } from './NavLogo';
import { NavMobileDrawer } from './NavMobileDrawer';
import { getOrigin, getSubDomain } from '@guider-global/front-end-utils';
import { NavDesktopLinks } from './NavDesktopLinks';
import { NavUserMenu } from './NavUserMenu';
import {
  useLocalization,
  useMetrics,
  useMixpanelEvents,
  useProfiles,
  useUsers,
} from 'hooks';
import { useAuth } from '@guider-global/auth-hooks';
import { useIntercom } from 'react-use-intercom';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { selectRegistrationType } from 'store/slices/appSlice';
import { NavUnauthenticatedActions } from './NavUnauthenticatedActions';
import { MenuItemProps } from 'components';

export interface INavbarLink {
  type: 'button' | 'link';
  label: string;
  route?: string;
  action?: () => Promise<void> | void;
  variant?: 'contained' | 'outlined' | 'text';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  textColor?: string;
  key?: number | string;
}

export interface NavbarProps {
  hidden?: boolean;
}

export const Navbar = ({ hidden }: NavbarProps) => {
  // Redux
  const { unauthenticatedActions: navbarUnauthenticatedActions } =
    useAppSelector((state: RootState) => {
      return state.app.navbar;
    });
  const registrationPageType = useAppSelector(selectRegistrationType);

  //  State
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(
    null,
  );
  const [desktopLinksAnchor, setDesktopLinksAnchor] =
    useState<null | HTMLElement>(null);

  // - MixPanel
  const { trackMixpanelEvent, resetUserIdentity } = useMixpanelEvents({});

  // - Auth0
  const { logout, isAuthenticated } = useAuth({
    waitForAuthentication: true,
  });
  // - Intercom
  const {
    show: showIntercom,
    hide: hideIntercom,
    isOpen: isIntercomOpen,
  } = useIntercom();
  // Utils
  const organizationSlug = getSubDomain();

  // Localization
  const { localeCode, handleLanguageChange } =
    useLocalization(organizationSlug);
  // Base languages
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguages = getBaseLanguage();
  const navigation = baseLanguages?.navigation;

  // UseSanityOrganization
  const { getOrganization } = useSanityOrganization({
    getSilently: false,
    organizationSlug,
  });
  const sanityOrganization = getOrganization();
  const organizationLanguages = sanityOrganization.organizationLanguages;

  const whiteLabel = sanityOrganization.white_label;
  const learningHub = sanityOrganization.learning_hub;
  const organizationLearningHubLabel = learningHub?.navigation_label;

  const { integrations: enabledIntegrations, logo_size: logoSize } = whiteLabel;
  const videoConferencing = enabledIntegrations?.video_conferencing;
  const calendar = enabledIntegrations?.calendar;
  const activeDirectoryTeams = videoConferencing?.active_directory_teams;
  const activeDirectoryOutlookCalendar =
    calendar?.active_directory_outlook_calendar;

  const isIntegrationEnabled =
    activeDirectoryTeams || activeDirectoryOutlookCalendar;

  // User
  const { users: getUsers } = useUsers({ getSilently: false });
  const users = getUsers();
  const user = users?.[0];

  // Profile
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();

  // Metrics
  const { hasMetricPermissions } = useMetrics({
    getSilentlyUrl: `/metrics?organizationSlug=${organizationSlug}`,
  });

  const whiteLabelLogo = buildSanityImageUrl({
    source: whiteLabel.logo,
  });

  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuAnchor(event.currentTarget);
    trackMixpanelEvent('Navigation - User Settings dropdown');
  };
  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };
  const handleAdditionalNavLinksClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setDesktopLinksAnchor(event.currentTarget);
    trackMixpanelEvent('Navigation - Additional resources dropdown');
  };
  const handleAdditionalNavLinksClose = () => {
    setDesktopLinksAnchor(null);
  };

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: getOrigin() } });
    trackMixpanelEvent('Navigation - User Settings - Log out');
    resetUserIdentity();
  };

  const handleUserChangeLanguage = (localeCode: string) => {
    handleLanguageChange(localeCode);
    handleUserMenuClose();
  };

  const filteredAdditionalNavLinks = !isAuthenticated
    ? whiteLabel?.additional_nav_links?.filter((link) => !link.is_private) || []
    : whiteLabel?.additional_nav_links;

  const formattedAdditionalNavLinks: MenuItemProps[] =
    filteredAdditionalNavLinks?.map((item) => {
      return {
        key: `other-resources-link-${item._key}`,
        action: handleAdditionalNavLinksClose,
        href: item.url,
        label: item.label,
        component: Link,
        'data-cy': item._key,
        muiItemProps: {
          target: item.target,
          rel: 'noopener noreferrer',
          underline: 'none',
          color: 'inherit',
        },
      };
    });

  const authenticatedLinks: INavbarLink[] = [
    {
      route: '/dashboard',
      label: `${navigation.dashboard_link_label}`,
      type: 'link',
    },
    {
      route: '/programs',
      label: `${navigation.programs_link_label}`,
      type: 'link',
      key: 1,
    },

    {
      route: '/relationships',
      label: `${navigation.relationships_link_label}`,
      type: 'link',
      key: 2,
    },
    {
      route: '/goals',
      label: `${navigation.goals_link_label}`,
      type: 'link',
      key: 3,
    },
    {
      route: '/learn',
      label:
        organizationLearningHubLabel ??
        `${navigation.learning_center_link_label}`,
      type: 'link',
      key: 4,
    },
    {
      label: `${navigation.support_link_label}`,
      type: 'button',
      action: () => {
        trackMixpanelEvent('Navigation - Support');
        isIntercomOpen ? hideIntercom() : showIntercom();
      },
      key: 5,
    },
  ];

  if (hasMetricPermissions) {
    authenticatedLinks.push({
      label: 'Admin',
      type: 'button',
      key: 6,
      action: () => {
        window.location.href = `https://${organizationSlug}.admin.guider.app`;
      },
    });
  }

  const getUnauthenticatedActions = (): INavbarLink[] => {
    if (registrationPageType === 'none') return [];

    if (navbarUnauthenticatedActions) return navbarUnauthenticatedActions;

    return [
      {
        label: `${navigation.login_button_label}`,
        route: '/login',
        type: 'button',
        variant: 'outlined',
        key: 1,
      },
      {
        label: `${navigation.register_button_label}`,
        route: '/register/account',
        type: 'button',
        variant: 'contained',
        color: 'info',
        key: 2,
      },
    ];
  };

  const unauthenticatedActions: INavbarLink[] = getUnauthenticatedActions();

  const integrationsNavUserMenuItem = isIntegrationEnabled
    ? [
        {
          action: () => {
            handleUserMenuClose();
            trackMixpanelEvent('Navigation - User Settings - Integrations');
          },
          component: RouterLink,
          to: '/settings/integrations',
          'data-cy': 'components_Navbar_settings_integrations-link',
          label: navigation.user_action_menu.integrations_label,
        },
      ]
    : [];

  const navUserMenuItems: MenuItemProps[] = [
    {
      action: () => {
        handleUserMenuClose();
        trackMixpanelEvent('Navigation - User Settings - Profile');
      },
      component: RouterLink,
      to: '/settings/profile',
      'data-cy': 'components_Navbar_settings_profile-link',
      label: navigation.user_action_menu.profile_link_label,
    },
    {
      action: () => {
        handleUserMenuClose();
        trackMixpanelEvent('Navigation - User Settings - Account');
      },
      component: RouterLink,
      to: '/settings/account',
      'data-cy': 'components_Navbar_settings_account-link',
      label: navigation.user_action_menu.account_link_label,
    },
    ...integrationsNavUserMenuItem,
    {
      action: () => {
        handleUserMenuClose();
        trackMixpanelEvent('Navigation - User Settings - Availability');
      },
      component: RouterLink,
      to: '/settings/availability',
      'data-cy': 'components_Navbar_settings_availability-link',
      label: navigation.user_action_menu.availability_link_label,
    },
  ];

  if (hidden) return <></>;

  return (
    <AppBar position="sticky" sx={{ boxShadow: 'none', minHeight: '64px' }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          minHeight: '64px',
        }}
      >
        <NavMobileDrawer
          links={isAuthenticated ? authenticatedLinks : unauthenticatedActions}
          additionalNavLinks={filteredAdditionalNavLinks}
          additionalNavLinksLabel={navigation?.other_resources_link_label}
        />
        <NavLogo src={whiteLabelLogo} size={logoSize} />
        <NavDesktopLinks
          links={authenticatedLinks}
          additionalNavLinks={formattedAdditionalNavLinks}
          anchorEl={desktopLinksAnchor}
          onDesktopLinksClickOpen={handleAdditionalNavLinksClick}
          onDesktopLinksClickClosed={handleAdditionalNavLinksClose}
          additionalNavLinksLabel={navigation.other_resources_link_label}
          isAuthenticated={isAuthenticated}
        />
        <NavUserMenu
          items={navUserMenuItems}
          profileLabel={navigation.user_action_menu.profile_link_label}
          profileTo="/settings/profile"
          user={user}
          profile={profile}
          onClickLogout={handleLogout}
          logoutLabel={navigation.user_action_menu.log_out_link_label}
          onMenuClickOpen={handleUserMenuClick}
          onMenuClickClosed={handleUserMenuClose}
          anchorEl={userMenuAnchor}
          show={isAuthenticated}
          languageOptions={organizationLanguages}
          ariaLabelLanguage={
            navigation.user_action_menu.translation_dropdown_label
          }
          onChangeLocaleCode={handleUserChangeLanguage}
          localeCode={localeCode}
        />
        <NavUnauthenticatedActions
          actions={unauthenticatedActions}
          show={!isAuthenticated}
          languageOptions={organizationLanguages}
          ariaLabelLanguage={
            navigation.user_action_menu.translation_dropdown_label
          }
          onClickChangeLanguage={handleLanguageChange}
          localeCode={localeCode}
        />
      </Toolbar>
    </AppBar>
  );
};
