import { FC } from 'react';

import { SanityContentLibrary } from '@guider-global/shared-types';
import { Box, Grid, Typography } from '@mui/material';
import { Header } from '../Header';

type Props = SanityContentLibrary;

export const Video: FC<Props> = ({
  content: { excerpt, title, video },
  metadata: { type },
}) => {
  const videoUrl = video ? video : '';

  return (
    <Grid item xs={12} md={6} sx={{ mb: 8, mx: 'auto' }}>
      <Box
        component="iframe"
        src={videoUrl}
        sx={{
          height: '280px',
          width: '100%',
          borderRadius: 1,
          border: 'none',
          mb: 3,
        }}
        title={title}
        allowFullScreen
      />
      <Header {...{ heading: title, type }} />
      <Typography variant="body1">{excerpt ? excerpt : ''}</Typography>
    </Grid>
  );
};
