import { ListItemButton } from '@mui/material';
import { INavbarLink } from '../..';

export interface NavLinkListProps {
  links: INavbarLink[];
  linkActionCallback: (link: INavbarLink) => void;
}
export const NavLinkList = ({
  links,
  linkActionCallback,
}: NavLinkListProps) => {
  return (
    <>
      {links.map((link, index) => {
        return (
          <ListItemButton
            key={`navbar-link-${index}`}
            color="inherit"
            onClick={() => linkActionCallback(link)}
            sx={{
              fontSize: '1rem',
              padding: 2,
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            {link.label}
          </ListItemButton>
        );
      })}
    </>
  );
};
