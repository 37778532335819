import { Button } from '@mui/material';
import { INavbarLink } from 'components/Navbar';
import { Link as RouterLink } from 'react-router-dom';

export interface NavButtonProps {
  link: INavbarLink;
  isUnauthenticated?: boolean;
  'data-cy': string;
}

export const NavButton = ({
  link,
  isUnauthenticated,
  'data-cy': dataCy,
}: NavButtonProps) => {
  return (
    <>
      <Button
        {...(link.type !== 'link' && {
          disableElevation: true,
        })}
        variant={link?.variant || 'text'}
        color={link?.color || 'inherit'}
        sx={{
          ...(link.textColor && { color: link.textColor }),
          ...(isUnauthenticated && { marginLeft: 1 }),
          textTransform: 'none',
        }}
        {...(link?.action && { onClick: link.action })}
        {...(link?.route && { to: link.route, component: RouterLink })}
        data-cy={dataCy}
      >
        {link.label}
      </Button>
    </>
  );
};
