/* eslint-disable camelcase */

import React from 'react';

import { SanityContentLibrary } from '@guider-global/shared-types';
import { Box, Grid } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { Header } from '../Header';

type Props = SanityContentLibrary;

export const Article: React.FC<Props> = ({
  content: { image, title, article_content },
  metadata: { type },
}) => {
  return (
    <Grid item xs={12} md={6} sx={{ mb: 8, mx: 'auto' }}>
      <Box
        component="img"
        src={buildSanityImageUrl({ source: image })}
        sx={{
          height: '280px',
          width: '100%',
          borderRadius: 1,
          objectFit: 'cover',
          mb: 3,
        }}
      />
      <Header {...{ heading: title, type }} />
      <Box
        component={PortableText}
        value={article_content ? article_content : []}
      />
    </Grid>
  );
};
