import React, { useMemo, useState } from 'react';
// internal
import { IUser, UserIntegrationName } from '@guider-global/shared-types';

import { useUserIntegrations, useUsers } from 'hooks';

//store

// components
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { Box } from '@mui/material';
import { getImageSource } from 'utils/getImageSource';
import { IntegrationsCard, IntegrationsCardProps } from './IntegrationsCard';

interface IntegrationCardList extends IntegrationsCardProps {
  key: string;
}

export const IntegrationsList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { settings } = useSanitySettings({ getSilently: true });

  const msTeamImageSrc = getImageSource({
    source: settings?.static_media.integrations.active_directory_teams,
  });
  const outlookCalendarImageSrc = getImageSource({
    source:
      settings?.static_media.integrations.active_directory_outlook_calendar,
  });
  // Subdomain
  const organizationSlug = getSubDomain();

  // Hooks
  // - Users
  const { users } = useUsers({ waitForAuthentication: true });
  const user: IUser = users()[0];

  // - Integrations
  const { userIntegrations, reqUserIntegrations, isLoadingUserIntegrations } =
    useUserIntegrations({
      waitForAuthentication: true,
    });

  const ADOutlookIntegration = useMemo(
    () =>
      userIntegrations().find(
        (userIntegration) =>
          userIntegration.integrationName ===
          'active-directory-outlook-calendar',
      ),
    [userIntegrations],
  );

  const ADTeamsIntegration = useMemo(
    () =>
      userIntegrations().find(
        (userIntegration) =>
          userIntegration.integrationName === 'active-directory-teams',
      ),
    [userIntegrations],
  );

  // - Base language
  const { getBaseLanguage } = useSanityBaseLanguage({});

  const baseLanguage = getBaseLanguage();
  const {
    active_directory_outlook_calendar_label: outlookCalendarLabel,
    active_directory_outlook_calendar_description: outlookCalendarDescription,
    active_directory_outlook_teams_label: outlookTeamsLabel,
    active_directory_outlook_teams_description: outlookTeamsDescription,
  } = baseLanguage.settings.integrations;

  // - Organization

  const { getOrganization } = useSanityOrganization({
    organizationSlug,
    getSilently: false,
  });
  const { white_label: whiteLabel } = getOrganization();
  const { integrations: enabledIntegrations } = whiteLabel;
  const { video_conferencing: videoConferencing, calendar } =
    enabledIntegrations;

  // Events
  const handleDisconnectIntegration = async (integrationName: string) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const integrationIdToDisconnect = userIntegrations().find(
      (userIntegration) => userIntegration.integrationName === integrationName,
    )?.integrationName;

    await reqUserIntegrations({
      method: 'DELETE',
      url: `/userIntegrations/${integrationIdToDisconnect}`,
    });

    await reqUserIntegrations({ url: '/userIntegrations' });
  };

  const handleConnectIntegration = async (
    integrationName: UserIntegrationName,
  ) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    await reqUserIntegrations({
      method: 'PUT',
      url: `/userIntegrations/${integrationName}`,
      data: {
        integrationName,
        features:
          integrationName === 'active-directory-outlook-calendar'
            ? ['sessions:availabilities:retrieve']
            : integrationName === 'active-directory-teams'
            ? ['sessions:videoConferencing:create']
            : [],
      },
    });

    await reqUserIntegrations({ url: '/userIntegrations' });
  };

  const cardTypes: IntegrationCardList[] = [
    {
      key: 'active-directory-outlook-calendar',
      iconSource: outlookCalendarImageSrc,
      header: outlookCalendarLabel,
      subheader: ADOutlookIntegration
        ? user?.email || ''
        : outlookCalendarDescription,
      connected: !!ADOutlookIntegration,
      onClickDisconnect: async () =>
        await handleDisconnectIntegration('active-directory-outlook-calendar'),
      onClickConnect: async () =>
        await handleConnectIntegration('active-directory-outlook-calendar'),
      enabled: calendar.active_directory_outlook_calendar,
      loading: isLoadingUserIntegrations() || isLoading,
    },
    {
      key: 'active-directory-teams',
      iconSource: msTeamImageSrc,
      header: outlookTeamsLabel,
      subheader: ADTeamsIntegration
        ? user?.email || ''
        : outlookTeamsDescription,
      connected: !!ADTeamsIntegration,
      onClickDisconnect: async () =>
        await handleDisconnectIntegration('active-directory-teams'),
      onClickConnect: async () =>
        await handleConnectIntegration('active-directory-teams'),
      enabled: videoConferencing.active_directory_teams,
      loading: isLoadingUserIntegrations() || isLoading,
    },
  ];
  return (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', gap: 3 }}>
      {cardTypes.map((card) => (
        <IntegrationsCard
          key={card.key}
          iconSource={card.iconSource}
          header={card.header}
          subheader={card.subheader}
          connected={card.connected}
          onClickDisconnect={card.onClickDisconnect}
          onClickConnect={card.onClickConnect}
          enabled={card.enabled}
          loading={card.loading}
        />
      ))}
    </Box>
  );
};
export { IntegrationsCard } from './IntegrationsCard';
